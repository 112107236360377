import * as React from "react"
import Nav from "../components/Nav";
import AboutSection from "../components/section/AboutSection";
import ContactSection from "../components/section/ContactSection";
import HomeSection from "../components/section/HomeSection";
import ProjectsSection from "../components/section/ProjectsSection";
import SoundsSection from "../components/section/SoundsSection";
import Wave from "../components/Wave";
import { Helmet } from "react-helmet"


const IndexPage = () => {

  const TITLE = 'Alessio Premoli | fullstack developer & sound artist';
  const DESCR = 'Official site of freelance full stack developer and sound artist Alessio Premoli. Specialized in developing app and webapp using Angular, React, Python, Node, Typescript and C#.';
  const URLSE = 'https://alessiopremoli.dev';
  const URLNS = 'http://alessiopremoli.dev';

  return (
    <main>
      <Helmet>
        {/* <!-- Primary Meta Tags --> */}
        <meta charSet="utf-8" />
        <title>{TITLE}</title>
        <meta name="description" content={DESCR} />

        {/* <!-- Open Graph / Facebook --> */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={URLSE} />
        <meta property="og:title" content={TITLE} />
        <meta property="og:description" content={DESCR} />
        <meta property="og:image" content={`${URLNS}/alessiopremoli.png"`} />
        <meta property="og:image:secure_url" content={`${URLSE}/alessiopremoli.png"`} />

        {/* <!-- Twitter --> */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={URL} />
        <meta property="twitter:title" content={TITLE} />
        <meta property="twitter:description" content={DESCR} />
        <meta property="twitter:image" content={`${URLNS}/alessiopremoli.png"`} />
      </Helmet>
      <Wave />
      <Nav />
      <HomeSection />
      <AboutSection />
      <ProjectsSection />
      <SoundsSection />
      <ContactSection />
    </main>
  )
}

export default IndexPage
