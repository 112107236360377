import React from "react";
import classNames from "classnames";
import AngularIcon from "./svg/Angular";
import ReactIcon from "./svg/React";
import MicrosoftSqlServerIcon from "./svg/MicrosoftSqlServer";
import IonicIcon from "./svg/Ionic";
import GatsbyIcon from "./svg/Gatsby";
import HtmlIcon from "./svg/Html";
import CssIcon from "./svg/Css";
import TypescriptIcon from "./svg/Typescript";
import JavascriptIcon from "./svg/Javascript";
import ExpressIcon from "./svg/Express";
import PythonIcon from "./svg/Python";
import DjangoIcon from "./svg/Django";
import CsharpIcon from "./svg/Csharp";
import PostgresIcon from "./svg/Postgres";
import MongoIcon from "./svg/Mongo";
import GitIcon from "./svg/Git";
import DockerIcon from "./svg/Docker";
import KubernetesIcon from "./svg/Kubernetes";

const tidy = (string) => string.toLowerCase().trim();

const getIcon = name => {
    switch (tidy(name)) {
        case "angular":
            return <AngularIcon />
        case "react":
            return <ReactIcon />
        case "íonic":
            return <IonicIcon />
        case "gatsby":
            return <GatsbyIcon />
        case "html5":
            return <HtmlIcon />
        case "css3":
            return <CssIcon />
        case "typescript":
            return <TypescriptIcon />
        case "javascript":
            return <JavascriptIcon />
        case "microsoftsqlserver":
            return <MicrosoftSqlServerIcon />
        case "python":
            return <PythonIcon />
        case "express":
            return <ExpressIcon />
        case "django":
            return <DjangoIcon />
        case "c#":
            return <CsharpIcon />
        case "postgres":
            return <PostgresIcon />
        case "mongo":
            return <MongoIcon />
        case "git":
            return <GitIcon />
        case "docker":
            return <DockerIcon />
        case "kubernetes":
            return <KubernetesIcon />
        default:
            return <></>
    }
};

const Icons = ({ name, classes, color }) => (
    <div id={`${tidy(name)}-logo-icon`} className={classNames("icon", classes)}>
        {getIcon(tidy(name), color)}
    </div>
);

export default Icons;