import React from "react";
import classNames from "classnames";
import AtelierPiccoliBit from "./png/AtelierPiccoliBit";
import ChelidonFrame from "./png/ChelidonFrame";
import Covid19 from "./png/Covid19";
import Aps232 from "./png/232Aps";


const tidy = (string) => string.toLowerCase().trim();

const getImage = name => {
    switch (tidy(name)) {
        case "atelierpiccolibit":
            return <AtelierPiccoliBit />;
        case "chelidonframe":
            return <ChelidonFrame />
        case "covid19":
            return <Covid19 />
        case "232aps":
            return <Aps232 />
        default:
            return <></>
    }
}

const DynamicImage = ({ name, classes = null }) => (
    <div id={`${tidy(name)}-image`} className={classNames("imagePrj", classes)}>
        {getImage(tidy(name))}
    </div>
)

export default DynamicImage;