import * as React from "react"
import Icons from "./imagesImport/icons";
import * as styles from "./ChipSkill.module.css";
import SlideLeftWrapper from "./SlideLeftWrapper";


const ChipSkill = ({ skill }) => {

    return (
        <SlideLeftWrapper>
            <div aria-label={skill} className={styles.gridElement}><Icons name={skill} classes="size-xs" /></div>
        </SlideLeftWrapper>
    )
}

export default ChipSkill;