
import * as React from "react"
import CardLayout from "../CardLayout";
import ChipSkill from "../ChipSkill";
import { MY_LINKS } from "../data/links";
import H3 from "../H3";
import * as styles from "./AboutSection.module.css";


const AboutSection = () => {

    const experienceYears = (new Date()).getFullYear() - 2013;

    const skills = [
        'angular', 'react', 'íonic', 'gatsby', 'html5', 'css3',
        'typescript', 'javascript', 'express', 'python', 'django', 'c#', 'postgres', 'microsoftsqlserver', 'mongo',
        'git', 'docker', 'kubernetes'
    ]

    return (
        <section id="about">
            <div className={styles.container}>
                <div>
                    <H3 heading="About"></H3>
                    <CardLayout>
                        <div className={styles.experienceContainer}>
                            <p>👋 Hello, I'm Alessio, a freelance full stack developer with {experienceYears} years of experience working in fintech, with a background in mathematics and based in Milan, Italy. </p>

                            <p>Passioned about web technologies, experimental sounds and generative art. Multi-year experience using technologies such as Angular, Ionic, React, Python and C#, designind and maintaining web application, mobile apps, rest clients, and working with microservices infrastructures deployed on Kubernetes. </p>

                            <p>Freelancer soundartist, working in the field of data sonification, sound manipulation and in the intersections between code, design and sound. </p>
                            {/* <p>Full stack developer with {experienceYears} years of experience working in fintech, with a background in mathematics and based in Milan, Italy.</p>
                            <p>Sound-artist specialized in the data-sonification field, using sound and code to reninterpret numbers and data</p>
                            <p>Passioned about web technologies, experimental sounds and generative art. Multi-year experience using technologies such as Angular, Ionic, React, Python and C#, designind and maintaining web application, mobile apps, and rest clients.</p> */}
                            <p style={{ marginBottom: 0, marginTop: "1rem" }}>Check out my résumé <a href={MY_LINKS.RESUME} target="_blank" rel="noreferrer">here</a> and take a look to my skills right here.</p>
                        </div>
                    </CardLayout>
                </div>
                <div className={styles.secondHeader}>
                    <H3 heading="Skills"></H3>
                    <div className={styles.grid}>
                        {skills.map(s => <ChipSkill skill={s}  key={s} />)}
                        {/* {skills.map(s => <div aria-label={s} key={s} className={styles.gridElement}><Icons name={s} classes="size-xs" /></div>)} */}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutSection;