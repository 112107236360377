export const projects = [
    {
        title: 'Atelier PiccoliBit',
        description: 'An online school for digital art, music, poetry, and dance, founded by Andrea Marinelli and me. \n\nAndrea is the creative mind behind the project, while I take care of the UI, the UX and the full development, including a simople architecture. \n\nDeveloped with Angular, Express and MongoDB (MEAN) and deployed on Netlify.',
        url: 'https://www.atelierpiccolibit.it/',
        imageName: 'atelierpiccolibit',
        stack: ['Angular', 'Express', 'MongoDB', 'Netlify']
    },
    {
        title: 'Chelidon Frame official site',
        description: 'Official site for my experimental electronic project Chelidon Frame.\n\nChelidon Frame is an experimental electronic music project that mainly works with field recordings, radio interferences, guitars and processed sounds.',
        url: 'https://chelidonframe.site/',
        imageName: 'ChelidonFrame',
        stack: ['Angular', 'Netlify']
    },
    {
        title: 'COVID-19 Data Sonification',
        description: 'An online web audio installation that turns the daily numbers of confirmed cases, recovered patients and new deaths into ever-evolving sound waves. \n\nDay by day, as the Covid-19 situation evolves, the numbers change, and so do the frequencies of the sounds that we hear, abandoning the initial harmony.',
        url: 'https://covid19-data-sonification.space/',
        imageName: 'covid19',
        stack: ['Angular', 'Netlify', "ToneJs"]
    },
    {
        title: '232APS official site',
        description: 'Official site for 232APS Association, created with the aim of promoting artistic and educational paths for minors and young adults through using Hip-Hop\n\nDeveloped using Wix to have an available-to-everyone user interface to add contents and posts. The theme used is a custom-made one, developed from the three colors (organge, withe and black) of the logo',
        url: 'https://www.associazione232.org/',
        imageName: '232aps',
        stack: ['WIX']
    }
]
