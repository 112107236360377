
import * as React from "react"
import * as styles from "./ProjectCard.module.css";
import CardLayout from "./CardLayout";
import Chip from "./Chip";
import DynamicImage from "./../components/imagesImport/images";


const ProjectCard = ({ title, description, url, imageName, stack, triggerOnce = false }) => {
    return (
        <CardLayout triggerOnce={triggerOnce} full={true}>
            <div style={{ width: "100%" }}>
                <div className={styles.cardSplitter}>
                    <div className={styles.leftCard}>
                        <DynamicImage name={imageName} />
                    </div>
                    <div className={styles.right}>
                        <h4 className={styles.projectTitle}>{title}</h4>
                        <a href={url} target="_blank" className={styles.link} rel="noreferrer">{url}</a>
                        {stack.map((t, i) => <Chip key={i} chip={t} />)}
                        <p>{description}</p>
                    </div>
                </div>
            </div>
        </CardLayout>
    )
}

export default ProjectCard;