import * as React from "react";
import * as styles from "./H3.module.css";
import SlideLeftWrapper from "./SlideLeftWrapper";


const H3 = ({ heading }) => {

    return (
        <SlideLeftWrapper>
            <h3 className={styles.heading3}>{heading}</h3>
        </SlideLeftWrapper>
    )
};

export default H3;