import * as React from "react"
import classNames from "classnames";
import * as styles from "./CardLayout.module.css";
import SlideLeftWrapper from "./SlideLeftWrapper";


const CardLayout = ({ children, full = false, resizeHeight = false, animate = false, triggerOnce = false }) => {

    return (
        <SlideLeftWrapper triggerOnce={triggerOnce}>
            <div className={full ? styles.full : ""}>
                <div className={classNames(
                        styles.cardLayout, 
                        full ? styles.cardLayoutFull : "", 
                        animate ? "cardAnimate" : "",
                        resizeHeight ? styles.resizeHeight : ""
                    )}>
                    {children}
                </div>
            </div>
        </SlideLeftWrapper>
    )
}

export default CardLayout;