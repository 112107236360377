// extracted by mini-css-extract-plugin
export var button = "ContactSection-module--button--reFbR";
export var buttonsContainer = "ContactSection-module--buttonsContainer--SpRp7";
export var container = "ContactSection-module--container--OptNh";
export var errorInput = "ContactSection-module--errorInput---X4vz";
export var fadeIn = "ContactSection-module--fadeIn--eW0m1";
export var form = "ContactSection-module--form--J4Aej";
export var formElement = "ContactSection-module--formElement--74qyZ";
export var halfFormElement = "ContactSection-module--halfFormElement--S0jtc";
export var inLineInputs = "ContactSection-module--inLineInputs--vFU-Z";
export var label = "ContactSection-module--label--9zNor";
export var styledInput = "ContactSection-module--styledInput--ZE66r";