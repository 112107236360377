import React, { useState, Fragment } from "react"
import * as styles from "./Nav.module.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faBars, faTimes } from '@fortawesome/free-solid-svg-icons'
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import classNames from "classnames";


const Nav = () => {

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const updateMenuState = () => {
        setIsMenuOpen(!isMenuOpen);
    }

    return (
        <Fragment>
            <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} className={styles.barsMenu} size={'2x'} onClick={updateMenuState} />
            <nav className={classNames(styles.nav, isMenuOpen ? styles.open : styles.closed)}>
                <AnchorLink className={classNames(styles.linkNo, 'slide-top')} to="/#home" stripHash onAnchorLinkClick={updateMenuState}><FontAwesomeIcon icon={faHome} /></AnchorLink>
                <AnchorLink className={classNames(styles.link, 'slide-top')} to="/#about" stripHash onAnchorLinkClick={updateMenuState}>about</AnchorLink>
                <AnchorLink className={classNames(styles.link, 'slide-top')} to="/#projects" stripHash onAnchorLinkClick={updateMenuState}>projects</AnchorLink>
                <AnchorLink className={classNames(styles.link, 'slide-top')} to="/#sounds" stripHash onAnchorLinkClick={updateMenuState}>sounds</AnchorLink>
                <AnchorLink className={classNames(styles.link, 'slide-top')} to="/#contact" stripHash onAnchorLinkClick={updateMenuState}>contact</AnchorLink>
            </nav>
        </Fragment>
    )
}

export default Nav