import * as React from "react"
import * as styles from "./Chip.module.css";


const mapNameToIcon = {
    KUBERNETES: "https://img.shields.io/badge/-Kubernetes-326CE5?logo=Kubernetes&logoColor=white&style=flat-square",
    IONIC: "https://img.shields.io/badge/-Ionic-3880FF?logo=Ionic&logoColor=white&style=flat-square",
    DOCKER: "https://img.shields.io/badge/-Docker-2496ED?logo=Docker&logoColor=white&style=flat-square",
    TYPESCRIPT: "https://img.shields.io/badge/-Typescript-3178C6?logo=Typescript&logoColor=white&style=flat-square",
    PROCESSING: "https://img.shields.io/badge/-Processing_Foundation-006699?logo=Processing-Foundation&logoColor=white&style=flat-square",
    CSS3: "https://img.shields.io/badge/-CSS3-1572B6?logo=CSS3&logoColor=white&style=flat-square",
    PYTHON: "https://img.shields.io/badge/-Python-3776AB?logo=Python&logoColor=white&style=flat-square",
    REACT: "https://img.shields.io/badge/-React-61DAFB?logo=react&logoColor=white&style=flat-square",
    NETLIFY: "https://img.shields.io/badge/-Netlify-00C7B7?logo=Netlify&logoColor=white&style=flat-square",
    MONGODB: "https://img.shields.io/badge/-MongoDB-47A248?logo=MongoDB&logoColor=white&style=flat-square",
    NODE: "https://img.shields.io/badge/-Node.Js-339933?logo=Node.Js&logoColor=white&style=flat-square",
    CSHARP: "https://img.shields.io/badge/-C_Sharp-239120?logo=C-Sharp&logoColor=white&style=flat-square",
    DJANGO: "https://img.shields.io/badge/-Django-092E20?logo=Django&logoColor=white&style=flat-square",
    EXPRESS: "https://img.shields.io/badge/-Express-000000?logo=Express&logoColor=white&style=flat-square",
    HEROKU: "https://img.shields.io/badge/-Heroku-430098?logo=Heroku&logoColor=white&style=flat-square",
    NET: "https://img.shields.io/badge/-.NET-512BD4?logo=.NET&logoColor=white&style=flat-square",
    GATSBY: "https://img.shields.io/badge/-Gatsby-663399?logo=Gatsby&logoColor=white&style=flat-square",
    P5JS: "https://img.shields.io/badge/-p5.js-ED225D?logo=p5.js&logoColor=white&style=flat-square",
    JEST: "https://img.shields.io/badge/-Jest-C21325?logo=Jest&logoColor=white&style=flat-square",
    ANGULAR: "https://img.shields.io/badge/-Angular-DD0031?logo=Angular&logoColor=white&style=flat-square",
    MICROSOFT_SQL_SERVER: "https://img.shields.io/badge/-Microsoft_SQL_Server-CC2927?logo=Microsoft SQL Server&logoColor=white&style=flat-square",
    NPM: "https://img.shields.io/badge/-npm-CB3837?logo=npm&logoColor=white&style=flat-square",
    REDIS: "https://img.shields.io/badge/-Redis-DC382D?logo=Redis&logoColor=white&style=flat-square",
    GIT: "https://img.shields.io/badge/-git-F05032?logo=git&logoColor=white&style=flat-square",
    HTML5: "https://img.shields.io/badge/-HTML5-E34F26?logo=HTML5&logoColor=white&style=flat-square",
    JAVASCRIPT: "https://img.shields.io/badge/-Javascript-F7DF1E?logo=Javascript&logoColor=white&style=flat-square",
    WIX: "https://img.shields.io/badge/-Wix-0C6EFC?logo=Wix&logoColor=white&style=flat-square"
}

const getDefault = name => `https://img.shields.io/badge/${name}-informational?style=flat-square`

const Chip = ({ chip }) => <div className={styles.chip}><img src={mapNameToIcon[chip.toUpperCase()] || getDefault(chip.toUpperCase())} alt={chip}></img></div>;

export default Chip;