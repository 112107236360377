
import * as React from "react"
import CardLayout from "../CardLayout";
import * as styles from "./ContactSection.module.css";
import classNames from "classnames";
import H3 from "../H3";
import { MY_LINKS } from "../data/links";
import { useForm } from "react-hook-form";

function encode(data) {
    return Object.keys(data)
        .map(
            key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
        )
        .join('&')
}


const ContactSection = () => {

    const { register, handleSubmit, formState: { errors }, reset } = useForm();

    const onSubmit = (data) => {
        fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({
                'form-name': 'contact',
                ...data,
            })
        })
            .then(response => {
                reset();
                alert("Thanks for reaching out, I'll get back to you as soon as possible!")
            })
            .catch(error => {
                alert('Something went wrong when sending your message.');
            })
    }

    return (
        <section id="contact">
            <div className={styles.container}>
                <H3 heading="Contact"></H3>
                <CardLayout full={true} resizeHeight={true}>
                    <div>
                        <p>You can reach me on <a href={MY_LINKS.GITHUB} target="_blank" rel="noreferrer">GitHub</a>, <a href={MY_LINKS.UPWORK} target="_blank" rel="noreferrer">Upwork</a> and <a href={MY_LINKS.LINKEDIN} target="_blank" rel="noreferrer">Linkedin</a>. I'm open to new projects, drop a line down here 🚀</p>
                        <form className={styles.form} method="post" netlify-honeypot="bot-field" data-netlify="true" name="contact" onSubmit={handleSubmit(onSubmit)}>
                            <input type="hidden" name="bot-field" />
                            <input type="hidden" name="form-name" value="contact" />
                            <p className={classNames('hidden')}>
                                <label>Don’t fill this out if you’re human: <input name="bot-field" /></label>
                            </p>
                            <div className={styles.inLineInputs}>
                                <div className={styles.halfFormElement}>
                                    <label className={styles.label} htmlFor="name">Name</label>
                                    <input className={classNames(styles.styledInput, errors.name?.type === 'required' ? styles.errorInput : '')}
                                        type="text" id="name" {...register("name", { required: true })} />
                                </div>
                                <div className={styles.halfFormElement}>
                                    <label className={styles.label} htmlFor="email">Email</label>
                                    <input className={classNames(styles.styledInput, errors.email?.type === 'required' ? styles.errorInput : '')}
                                        type="email" id="email" {...register("email", { required: true })} />
                                </div>

                            </div>

                            <div className={styles.formElement}>
                                <label className={styles.label} htmlFor="subject">Subject</label>
                                <input className={classNames(styles.styledInput, errors.subject?.type === 'required' ? styles.errorInput : '')}
                                    type="text" id="subject" {...register("subject", { required: true })} />
                            </div>

                            <div className={styles.formElement}>
                                <label className={styles.label} htmlFor="message">Message</label>
                                <textarea className={classNames(styles.styledInput, errors.message?.type === 'required' ? styles.errorInput : '')}
                                    id="message" {...register("message", { required: true })} rows="5" />
                            </div>

                            <div className={styles.buttonsContainer}>
                                <input className={classNames(styles.button, styles.send)} type="submit" value="Send" />
                            </div>
                        </form>
                    </div>
                </CardLayout>
            </div>
        </section>
    )
}

export default ContactSection;