import React, { useState, useEffect, useCallback } from "react";
import { useEmblaCarousel } from "embla-carousel/react";
import { PrevButton, NextButton } from "./EmblaCarouselButtons";
import { Slide } from "./EmblaCarouselSlide";
import "./css/embla.css";

const EmblaCarousel = ({ slides }) => {
    const [, setSlidesInView] = useState([]);
    const [viewportRef, embla] = useEmblaCarousel({ skipSnaps: false });
    const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
    const [nextBtnEnabled, setNextBtnEnabled] = useState(false);

    const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
    const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);
    const onSelect = useCallback(() => {
        if (!embla) return;
        setPrevBtnEnabled(embla.canScrollPrev());
        setNextBtnEnabled(embla.canScrollNext());
    }, [embla]);

    const findSlidesInView = useCallback(() => {
        if (!embla) return;

        setSlidesInView((slidesInView) => {
            if (slidesInView.length === embla.slideNodes().length) {
                embla.off("select", findSlidesInView);
            }
            const inView = embla
                .slidesInView(true)
                .filter((index) => slidesInView.indexOf(index) === -1);
            return slidesInView.concat(inView);
        });
    }, [embla, setSlidesInView]);

    useEffect(() => {
        if (!embla) return;
        onSelect();
        findSlidesInView();
        embla.on("select", onSelect);
        embla.on("select", findSlidesInView);
    }, [embla, onSelect, findSlidesInView]);

    return (
        <div className="embla">
            <div className="embla__viewport" ref={viewportRef}>
                <div className="embla__container">
                    {slides.map((slide, index) => (
                        <Slide
                            key={index}
                            inView={true}
                        >{slide}</Slide>
                    ))}
                </div>
            </div>
            <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
            <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
        </div>
    );
};

export default EmblaCarousel;
