
import * as React from "react"
import * as styles from "./HomeSection.module.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import classNames from "classnames";
import { MY_LINKS } from "../data/links";
import { useInView } from "react-intersection-observer";


const HomeSection = () => {

    const [refH1, inViewH1] = useInView({ triggerOnce: false });
    const [refH4, inViewH4] = useInView({ triggerOnce: false });


    return (
        <section id="home">
            <div className={styles.container}>
                <h1 ref={refH1} className={classNames(inViewH1 ? 'slide-top' : '', styles.heading1)}>Alessio Premoli</h1>
                <h4 ref={refH4} className={classNames(inViewH4 ? 'slide-left' : '', styles.heading4)}>👋 Hello, I'm a fullstack developer &amp; a sound artist
                    <span className={styles.linkContainer}>
                        <a href={MY_LINKS.GITHUB} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faGithub} /></a>&nbsp;&nbsp;
                        <a href={MY_LINKS.LINKEDIN} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faLinkedin} /></a>
                    </span>
                </h4>
            </div>
        </section>
    )
}

export default HomeSection;