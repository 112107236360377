import * as React from "react"
import { useInView } from "react-intersection-observer";
import classNames from "classnames";
import * as styles from "./SlideLeftWrapper.module.css";


const SlideLeftWrapper = ({children, triggerOnce = false}) => {

    const [ref, inView] = useInView({ triggerOnce });

    return (
        <div ref={ref} className={classNames(styles.opacityNone, inView ? 'slide-left' : '')}>{children}</div>
    )
}

export default SlideLeftWrapper;