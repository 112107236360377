import * as React from "react"
import * as styles from "./Wave.module.css";

const Wave = () => {

    const light = "rgba(165, 207, 227, 0.5)";
    const dark = "rgba(2, 132, 118, 0.2)";
    const darker = "rgba(0, 34, 28, 0.1)";

    return (
        <svg id="wave" className={styles.outer} viewBox="0 0 1440 220" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <path className={styles.firstWave} fill={light} d="M0,38L48,57C96,76,192,114,288,126.7C384,139,480,127,576,110.8C672,95,768,76,864,72.8C960,70,1056,82,1152,79.2C1248,76,1344,57,1440,60.2C1536,63,1632,89,1728,98.2C1824,108,1920,101,2016,98.2C2112,95,2208,95,2304,82.3C2400,70,2496,44,2592,50.7C2688,57,2784,95,2880,101.3C2976,108,3072,82,3168,60.2C3264,38,3360,19,3456,28.5C3552,38,3648,76,3744,104.5C3840,133,3936,152,4032,136.2C4128,120,4224,70,4320,41.2C4416,13,4512,6,4608,28.5C4704,51,4800,101,4896,104.5C4992,108,5088,63,5184,47.5C5280,32,5376,44,5472,53.8C5568,63,5664,70,5760,63.3C5856,57,5952,38,6048,38C6144,38,6240,57,6336,53.8C6432,51,6528,25,6624,31.7C6720,38,6816,76,6864,95L6912,114L6912,190L6864,190C6816,190,6720,190,6624,190C6528,190,6432,190,6336,190C6240,190,6144,190,6048,190C5952,190,5856,190,5760,190C5664,190,5568,190,5472,190C5376,190,5280,190,5184,190C5088,190,4992,190,4896,190C4800,190,4704,190,4608,190C4512,190,4416,190,4320,190C4224,190,4128,190,4032,190C3936,190,3840,190,3744,190C3648,190,3552,190,3456,190C3360,190,3264,190,3168,190C3072,190,2976,190,2880,190C2784,190,2688,190,2592,190C2496,190,2400,190,2304,190C2208,190,2112,190,2016,190C1920,190,1824,190,1728,190C1632,190,1536,190,1440,190C1344,190,1248,190,1152,190C1056,190,960,190,864,190C768,190,672,190,576,190C480,190,384,190,288,190C192,190,96,190,48,190L0,190Z" />
            <path className={styles.secondWave} fill={dark} d="M0,0L48,19C96,38,192,76,288,76C384,76,480,38,576,47.5C672,57,768,114,864,123.5C960,133,1056,95,1152,82.3C1248,70,1344,82,1440,79.2C1536,76,1632,57,1728,69.7C1824,82,1920,127,2016,129.8C2112,133,2208,95,2304,91.8C2400,89,2496,120,2592,129.8C2688,139,2784,127,2880,123.5C2976,120,3072,127,3168,107.7C3264,89,3360,44,3456,41.2C3552,38,3648,76,3744,85.5C3840,95,3936,76,4032,66.5C4128,57,4224,57,4320,72.8C4416,89,4512,120,4608,126.7C4704,133,4800,114,4896,88.7C4992,63,5088,32,5184,44.3C5280,57,5376,114,5472,133C5568,152,5664,133,5760,117.2C5856,101,5952,89,6048,82.3C6144,76,6240,76,6336,76C6432,76,6528,76,6624,85.5C6720,95,6816,114,6864,123.5L6912,133L6912,190L6864,190C6816,190,6720,190,6624,190C6528,190,6432,190,6336,190C6240,190,6144,190,6048,190C5952,190,5856,190,5760,190C5664,190,5568,190,5472,190C5376,190,5280,190,5184,190C5088,190,4992,190,4896,190C4800,190,4704,190,4608,190C4512,190,4416,190,4320,190C4224,190,4128,190,4032,190C3936,190,3840,190,3744,190C3648,190,3552,190,3456,190C3360,190,3264,190,3168,190C3072,190,2976,190,2880,190C2784,190,2688,190,2592,190C2496,190,2400,190,2304,190C2208,190,2112,190,2016,190C1920,190,1824,190,1728,190C1632,190,1536,190,1440,190C1344,190,1248,190,1152,190C1056,190,960,190,864,190C768,190,672,190,576,190C480,190,384,190,288,190C192,190,96,190,48,190L0,190Z" />
            <path className={styles.thirdWave} fill={darker} d="M0,171L48,145.7C96,120,192,70,288,53.8C384,38,480,57,576,66.5C672,76,768,76,864,63.3C960,51,1056,25,1152,25.3C1248,25,1344,51,1440,66.5C1536,82,1632,89,1728,79.2C1824,70,1920,44,2016,28.5C2112,13,2208,6,2304,12.7C2400,19,2496,38,2592,47.5C2688,57,2784,57,2880,50.7C2976,44,3072,32,3168,41.2C3264,51,3360,82,3456,98.2C3552,114,3648,114,3744,123.5C3840,133,3936,152,4032,145.7C4128,139,4224,108,4320,95C4416,82,4512,89,4608,101.3C4704,114,4800,133,4896,133C4992,133,5088,114,5184,107.7C5280,101,5376,108,5472,107.7C5568,108,5664,101,5760,82.3C5856,63,5952,32,6048,44.3C6144,57,6240,114,6336,133C6432,152,6528,133,6624,129.8C6720,127,6816,139,6864,145.7L6912,152L6912,190L6864,190C6816,190,6720,190,6624,190C6528,190,6432,190,6336,190C6240,190,6144,190,6048,190C5952,190,5856,190,5760,190C5664,190,5568,190,5472,190C5376,190,5280,190,5184,190C5088,190,4992,190,4896,190C4800,190,4704,190,4608,190C4512,190,4416,190,4320,190C4224,190,4128,190,4032,190C3936,190,3840,190,3744,190C3648,190,3552,190,3456,190C3360,190,3264,190,3168,190C3072,190,2976,190,2880,190C2784,190,2688,190,2592,190C2496,190,2400,190,2304,190C2208,190,2112,190,2016,190C1920,190,1824,190,1728,190C1632,190,1536,190,1440,190C1344,190,1248,190,1152,190C1056,190,960,190,864,190C768,190,672,190,576,190C480,190,384,190,288,190C192,190,96,190,48,190L0,190Z" />
        </svg>
    )
};

export default Wave;