import React from "react";

// const PLACEHOLDER_SRC = `data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs%3D`;

export const Slide = ({ children, inView }) => {
    // const [hasLoaded, setHasLoaded] = useState(false);

    return (
        <div className={`embla__slide ${inView ? "has-loaded" : ""}`}>
            <div className="embla__slide__inner">
                {children}
            </div>
        </div>
    );
};
