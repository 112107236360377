
import * as React from "react"
import * as styles from "./ProjectsSection.module.css";
import ProjectCard from "./../ProjectCard"
import EmblaCarousel from "./../carousel/EmblaCarousel"
import { projects } from "../data/projects";
import H3 from "../H3";


const ProjectsSection = () => {
    let slides = projects.map(project => <ProjectCard {...project} {...{ triggerOnce: true }}></ProjectCard>);

    return (
        <section id="projects">
            <div className={styles.container}>
                <H3 heading="Projects"></H3>
                <EmblaCarousel slides={slides} />
            </div>
        </section>
    )
}

export default ProjectsSection;