
import * as React from "react"
import * as styles from "./SoundsSection.module.css";
import H3 from "../H3";


const SoundsSection = () => {
    return (
        <section id="sounds">
            <div className={styles.container}>
                <H3 heading="Sounds"></H3>

            </div>
        </section>
    )
}

export default SoundsSection;